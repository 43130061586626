<template>
  <div class="layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="steps"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
    <div class="mainCont kids_dragStrokeToImagePuzzle">
      <div class="background">
        <div class="leftSide">
          <div
            class="phone"
            :class="[
              answersfound == 0 || callList[steps].isPhone || answersfound == callList.length
                ? '  isPhoneBackground'
                : answersfound == 1
                ? '  isSafeBackground'
                : [2, 3, 4].indexOf(answersfound) > -1
                ? 'isDoorBackground'
                : ''
            ]"
          >
            <div class="screen">
              <div class="numDisplay">
                <div
                  v-show="answersfound != 0 && !callList[steps].isPhone"
                  class="lock-wrapper"
                  :class="{ unlock: isCalling, isHidden: callList[steps].showLock == false }"
                >
                  <img
                    :src="
                      require('@/assets/img/16-GAMES/G39-phone-numbers/lock-top-part.svg')
                    "
                    class="lock-top"
                  />
                  <img
                    :src="
                      require('@/assets/img/16-GAMES/G39-phone-numbers/lock-bottom-part.svg')
                    "
                    class="lock-bottom"
                  />
                </div>
                <div class="display">
                  <div class="numbers">{{ displayText }}</div>
                </div>
                <div class="deletebutton" @click="removeNumber()">
                  <img :src="buttonList[13].image" />
                </div>
              </div>
              <div class="numPad">
                <div
                  v-show="index < 12"
                  class="buttons"
                  v-for="(item, index) in buttonList"
                  :key="index"
                  @click="addNumber(item.text)"
                  @mouseenter="buttonList[index].isHover = 1"
                  @mouseleave="buttonList[index].isHover = 0"
                >
                  <img :src="[item.isHover ? item.imageHover : item.image]" />
                </div>
                <!-- dummy button to keep the same spacing -->
                <div class="buttons"></div>
                <div
                  v-show="indexNum == 12"
                  class="buttons buttonsgreen"
                  :style="
                    callList[steps].hasDialButton == false
                      ? 'visibility:hidden'
                      : ''
                  "
                  v-for="(item, indexNum) in buttonList"
                  :key="indexNum + 'item'"
                  @click="callButton()"
                  @mouseenter="buttonList[indexNum].isHover = 1"
                  @mouseleave="buttonList[indexNum].isHover = 0"
                >
                  <img
                    class="yestse"
                    :src="[item.isHover ? item.imageHover : item.image]"
                  />
                </div>
                <!-- dummy button to keep the same spacing -->
                <div class="buttons"></div>
              </div>
            </div>
            <div
              v-show="(answersfound == 0 || callList[steps].isPhone) && isCalling"
              class="screen CallingScreen"
            >
              <div class="textWrap">
                <p class="numbers">{{ displayText }}</p>
                <p class="calling">calling....</p>
              </div>
              <div class="buttons buttonsred">
                <img :src="buttonList[14].image" />
              </div>
            </div>
          </div>
        </div>

        <div class="rightSide">
          <div class="mainCard">
            <div class="cardText">
              <div v-if="callList[steps].image" class="cardImage">
                <img :src="callList[steps].image" />
              </div>
              <div class="textWrap" v-if="callList[steps].pinying">
                <p class="pinyin font24">{{ callList[steps].pinying }}</p>
                <p class="hanzi font41">{{ callList[steps].hanzi }}</p>
              </div>
            </div>
            <span
              class="recognize_container_content_right_content_top volume-button"
              @click.stop="activeClick"
              @mouseenter="hoverImg(1, 'hover')"
              @mouseleave="hoverImg(1, '')"
              :class="{ lessononehover: isClickAudio }"
            >
              <AudioPlayer :isPlay="isStartPlaying" :audioUrl="songUrl" />
            </span>
          </div>
        </div>
      </div>

      <Star :totalStarNumber="callList.length" :currentIndex="answersfound" />
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import AudioPlayer from "@/components/Course/GamePage/AudioPlayer";
import Star from "../Star";

import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
    Star,
    AudioPlayer,
  },
  props: {
    callList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },

  PageButton,
  data() {
    return {
      isClickAudio:false,
      isStartPlaying:null,
      songUrl:null,
      allDone: false,
      answersfound: 0,
      clickedVolumePlay: 0,
      ShowPI: true,
      steps: 0,
      volumeImgSrc: require("@/assets/img/06-Sound/sound-white.svg"),
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),

      strockNotFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star-complete.svg"),

      ringSound: require("@/assets/audio/ringing.mp3"),
      nihaoSound: require("@/assets/audio/chapter_1/lesson_1/nihao.mp3"),
      woSound: require("@/assets/audio/chapter_1/lesson_1/wo.mp3"),
      shiSound: require("@/assets/audio/chapter_1/lesson_1/shi.mp3"),
      niSound: require("@/assets/audio/chapter_1/lesson_1/ni.mp3"),

      displayText: "",
      isCalling: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 4,
          },
        ],
      },
      isLastStep: false,
      buttonList: [
        {
          id: 1,
          text: "1",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-1.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-1-hover.svg"),
          isHover: 0,
        },
        {
          id: 2,
          text: "2",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-2.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-2-hover.svg"),
          isHover: 0,
        },
        {
          id: 3,
          text: "3",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-3.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-3-hover.svg"),
          isHover: 0,
        },
        {
          id: 4,
          text: "4",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-4.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-4-hover.svg"),
          isHover: 0,
        },
        {
          id: 5,
          text: "5",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-5.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-5-hover.svg"),
          isHover: 0,
        },
        {
          id: 6,
          text: "6",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-6.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-6-hover.svg"),
          isHover: 0,
        },
        {
          id: 7,
          text: "7",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-7.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-7-hover.svg"),
          isHover: 0,
        },
        {
          id: 8,
          text: "8",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-8.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-8-hover.svg"),
          isHover: 0,
        },
        {
          id: 9,
          text: "9",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-9.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-9-hover.svg"),
          isHover: 0,
        },
        {
          id: 10,
          text: "*",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-star.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-star-hover.svg"),
          isHover: 0,
        },
        {
          id: 11,
          text: "0",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-0.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-0-hover.svg"),
          isHover: 0,
        },
        {
          id: 12,
          text: "#",
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-pound.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-pound-hover.svg"),
          isHover: 0,
        },
        {
          id: 13,
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/button-call.svg"),
          imageHover: require("@/assets/img/16-GAMES/G39-phone-numbers/button-call-hover.svg"),
          isHover: 0,
        },
        {
          id: 14,
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/delete.svg"),
          isHover: 0,
        },
        {
          id: 15,
          image: require("@/assets/img/16-GAMES/G39-phone-numbers/phone-red.svg"),
          isHover: 0,
        },
      ],

      numberClickSocketInfo: {},
      clearSocketInfo: {},
      callSocketInfo: {},
    };
  },
  watch: {
    numberClickSocketInfo: {
      handler(value) {
        console.log(value);
        const data = {
          clickType: 2002001,
          data: { value: this.numberClickSocketInfo },
          text: "PhoneNumberGame点击数字socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clearSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002002,
          data: { value },
          text: "PhoneNumberGame点击清除socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    callSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2002003,
          data: { value },
          text: "PhoneNumberGame点击callsocket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("addNumber", ({ num }) => {
      this.addNumber(num, true);
    });
    this.$bus.$on("clearNumber", () => {
      this.removeNumber("socket", true);
    });
    this.$bus.$on("callPhone", () => {
      this.callButton("socket", true, true);
    });
  },
  methods: {
     hoverImg(id) {
      this.isClickAudio = !this.isClickAudio;
    },
    addNumber(num, isFromSocket = false) {
      if (!isFromSocket) {
        this.numberClickSocketInfo = {
          num,
          value: Math.random(),
        };
      }
      console.log(this.displayText);
      this.displayText = this.displayText + num;
      console.log(this.displayText);

      //if this game has no "dial" button, simulate a click on "call" after every number. Also don't reset the input
      if (this.callList[this.steps].hasDialButton == false) {
        this.callButton("socket", true, false);
      }
    },
    removeNumber(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clearSocketInfo = {
          value: Math.random(),
        };
      }
      this.displayText = this.displayText.slice(0, -1);
    },
    callButton(socket, isFromSocket = false, resetAfterSubmit = true) {
      //resetAfterSubmit : for some games, there is no "Dial" button. Every button will call "callButton()", and we don't want to reset the input
      // by deafult it will be reset
      if (!isFromSocket) {
        this.callSocketInfo = {
          value: Math.random(),
        };
      }

      if (
        this.displayText == this.callList[this.steps].number &&
        this.allDone == false
      ) {
        //right
        playCorrectSound(true, false);
        this.isCalling = true;
        setTimeout(() => {
          if (this.steps === this.callList.length - 1) {
            playCorrectSound();
            startConfetti();
            this.isLastStep = true;
            this.allDone = true;
            this.answersfound = this.callList.length;
          } else {
            this.answersfound++;
            this.steps++;
          }

          this.isCalling = false;
          this.displayText = "";
        }, 3000);
      } else {
        //wrong
        if (this.allDone == true && this.displayText == "*4409#") {
          this.isCalling = true;
          this.calldongdong();
        } else {
          if (resetAfterSubmit == true) {
            this.displayText = "";
          }
        }
      }
    },

    nextPage() {
      this.$bus.$emit("nextButton", true);
    },

    // calldongdong() {
    //   this.playsound(this.ringSound, 1);
    //   this.playsound(this.ringSound, 4000);
    //   this.playsound(this.nihaoSound, 8000);
    //   this.playsound(this.woSound, 9000);
    //   this.playsound(this.shiSound, 9700);
    //   this.playsound(this.niSound, 10250);
    //   setTimeout(() => {
    //     this.isCalling = false;
    //   }, 12000);
    // },

    playsound(sound, delay) {
      var n = new Audio(sound);

      setTimeout(() => {
        n.src = sound;
        n.play();
      }, delay);
    },

    updateNumbers(value) {
      this.numBers = value;
    },
    activeClick() {
      this.isStartPlaying = Math.random();
      // if (this.callList[this.steps].hanzi) {
      //   this.songUrl = this.callList[this.steps].hanzi;
      // } else {
        this.songUrl = this.callList[this.steps].sound;
      // };
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.strokeList {
  position: absolute;
  width: 14%;
  height: 30%;
  top: 10%;
  right: 20%;
  .strokeImage {
    width: 100%;
    margin-top: 20%;
    -webkit-user-drag: auto;
  }
}

.strokesFoundBoxes {
  position: absolute;
  right: 11%;
  top: 0;
  height: fit-content;
  padding: 5px 25px 15px 25px;
  display: flex;
  flex-direction: row;
  background-color: #f4d737;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 10px solid #d1b726;
  .strokesFoundimage {
    height: 100%;
    width: 27px;
    margin-top: 20%;
  }
  .outerBox {
    width: 100%;
    height: 100%;
    padding: 0px 4px;
    position: relative;
    display: flex;

    .innerBox {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #2472B3;
    }

    .innerBoxFound {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #F3D438;

      .strokesFoundimage {
        height: 65%;
        margin-top: 30%;
        // margin-left: 40%;
        margin-right: 5%;
      }
    }
  }
}
.recognize_container_content_right_content_top {
  width: 20%;
  margin-top: 5%;
  background: #ce4c3f;
  border-radius: 50%;
  padding: 0;
  @media screen and (max-width: 2200px) {
    // external screen
    width: 20%;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    width: 20%;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    width: 30%;
  }

  &.volume-button {
    cursor: pointer;
    width: 50px;
    height: 50px;
  }
  .lessononehover {
      background-color: #1b4589;
    }
  div {
    width: 50px;
    height: 50px;
    padding-bottom: 100%;
  }
  img {
    border-radius: 50%;
    background: #ce4c3f;
    width: 100%;
  }
}
.recognize_container_content_right_content_top:hover {
  // width: 20%;
  background: #b5392c;
  border-radius: 50%;
  // padding: 17px;
  div {
    width: 50px;
    height: 50px;
    padding-bottom: 100%;
  }
  img {
    border-radius: 50%;
    background: #b5392c;
    width: 100%;
  }
}
.mainCont {
  width: 100%;
  height: 100%;
  // height: 100%;
  // margin-top: 15px;
  // margin-left: 15px;
  background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
  //  background: #F7F4E0;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.leftSide {
  width: 50%;
}

.rightSide {
  width: 50%;

  .mainCard {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .cardText {
      background-color: #cd4c3f;
      height: 30%;
      width: 90%;
      border-radius: 20px;
      position: relative;
      margin-top: 100%;
      text-align: center;
      color: #fff;
      margin: 0;
      height: auto;
      .textWrap {
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 20px 0;
      }
      .pinyin {
        font-size: 2vh !important;
        // padding-top: 5%;
        font-family: "STHeitiSCPinyin-Light";
      }
      .hanzi {
        font-size: 3.5vh !important;
      }
      .cardImage {
        width: 100%;
        position: absolute;
        margin-top: -87%;

        img {
          width: 100%;
          border-radius: 20px;
        }
      }
    }
  }
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 85%;
  width: 90%;
  // background: #f7f4e0;
  border-radius: 48px;
  top: 10%;
  left: 5%;
  margin: auto;
  overflow: hidden;
  display: flex;
}
.phone {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: calc(70%);
  width: 50%;
  top: 10%;
  border-radius: 20px;
  background-color: #214e96;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1500px) {
    // laptop
    width: 60%;
    min-width: 250px;
  }

  .CallingScreen {
    position: absolute;
    z-index: 9;
    background-color: #112e5d !important;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .buttons {
      position: unset;
      margin-bottom: 20%;
    }
    .textWrap {
      margin-top: 20%;
      .numbers {
        color: white;
        font-size: 5vh;
      }
      .calling {
        color: #7485a1;
        font-size: 1.5vh;
      }
    }
  }

  .screen {
    width: 90%;
    height: 90%;
    background-color: #fff;
    border-radius: 20px;

    .numDisplay {
      //  background-color: green;
      width: 90%;
      height: 15%;
      margin-top: 5%;
      display: flex;
      border-radius: 10px;
      position: relative;
      .display {
        height: 100%;
        width: 80%;
        .numbers {
          // margin-top: 7%;
          overflow: hidden;
          font-size: 3vh;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
      .lock-wrapper {
        // position: absolute;
        padding-left: 20px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        flex: 30px;
        position: relative;
        .lock-bottom {
          position: absolute;
          z-index: 11;
          bottom: 8px;
          right: 0;
          width: 25px;
        }

        &.unlock .lock-top {
          transform: rotate(60deg);
          right: -4px;
        }
        .lock-top {
          position: absolute;
          right: 0px;
          width: 25px;
          top: 7px;
          transform: rotate(0deg);
          z-index: 10;
          transition-delay: 0.1s;
          transition-duration: 0.5s;
          transition-timing-function: ease-in-out;
        }
      }
    }
    .numPad {
      // background-color: green;
      width: 90%;
      height: 75%;
      margin-top: 5%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      // align-content: space-evenly;
      // place-content: space-evenly;
      justify-content: space-between;
    }
  }

  &.isDoorBackground {
    background-image: url("../../../../assets/img/16-GAMES/G39-phone-numbers/background-door.svg");
    margin-bottom: 10px !important;
    .numDisplay {
      background-color: #1b4589;
    }
    .display {
    }
  }

  &.isSafeBackground {
    background-image: url("../../../../assets/img/16-GAMES/G39-phone-numbers/background-safe.svg");

    .numDisplay {
      background-color: #224e96;

      .display {
      }
    }
  }

  &.isPhoneBackground {
    background-image: url("../../../../assets/img/16-GAMES/G39-phone-numbers/background-phone.svg");
    .display {
    }
    .display .numbers {
      color: #000000 !important;
    }
  }
  &.isSafeBackground,
  &.isDoorBackground,
  &.isPhoneBackground {
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    // max-height: 300px;
    width: 500px;
    min-height: 440px;

    @media screen and (max-width: 1700px) {
      width: 450px;

      .screen {
        height: 440px !important;
        width: 280px !important;

        .numDisplay {
          margin-top: 0px;
          margin-bottom: 40px !important;
        }
      }
    }
    &.isSafeBackground {
      @media screen and (max-width: 1450px) {
        .screen {
          width: 190px !important;
          .numDisplay {
            margin-bottom: 10px !important;
          }
        }
      }
    }

    @media screen and (max-width: 1450px) {
      width: 350px;

      .screen {
        height: 340px !important;
        width: 220px !important;
        .buttons {
          img {
            width: 40px !important;
          }
        }
      }
    }

    .buttons {
      img {
        width: 50px !important;
      }
    }
    @media screen and (max-height: 1200px) {
      .screen {
        height: 85% !important;
        width: 230px !important;
        // min-width: 230px !important;
        .numDisplay {
          margin-bottom: 10px !important;
        }
        @media screen and (max-height: 1200px) {
          height: 350px !important;
          // height:85% !important;
          .buttons img {
            width: 40px !important;
          }
        }
      }
    }

    .screen {
      background-color: transparent;
      width: 320px;
      height: 470px;
      display: flex;
      flex-flow: column;
      align-items: center;

      .deletebutton {
        display: flex;
        height: 100%;
        align-items: center;
        img {
          margin-top: 0;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
      .numPad {
        margin: 0px;
        height: auto;
        flex: 10px 1 0;

        .buttons {
          flex-basis: 27%;
          margin: 0;
          height: auto;
          img {
            height: auto;
            width: 60px;
          }
        }
      }

      .numDisplay {
        margin: 0px;
        height: 50px;
        align-content: center;

        @media screen and (max-width: 2200px) {
          margin-top: 20px;
          // external screen
        }

        @media screen and (max-height: 800px) {
          margin-bottom: 0;
        }
        .display {
          flex-grow: 1;
          overflow: hidden;
          display: flex;
          align-items: center;
          flex-flow: row;
          justify-content: flex-end;

          .numbers {
            height: auto;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.deletebutton {
  height: 100%;
  img {
    margin-top: 15%;
    height: 75%;
  }
}

.deletebutton:hover {
  transform: scale(0.9);
  cursor: pointer;
}

.deletebutton:active {
  transform: scale(0.95);
}
.buttons {
  height: 23%;
  // width: 20%;
  z-index: 1;
  border-radius: 50%;
  // background-color: #e4e5e4;
  // border-bottom: 4px solid #707070;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  // margin: 3% 6% 2% 6%;
  margin: 3% 0;
  flex-basis: 10%;
  cursor: pointer;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}
.buttonsred {
  background-color: #cd4c3f;
  border-bottom: 4px solid #a53d32;
  height: 12%;
  width: auto;
  position: absolute;
  bottom: 10%;
  left: 34%;
}
.buttonsred:hover {
  //  transform: scale(1.1);
  background-color: #cd4c3f;
  border-bottom: 4px solid #a53d32;
}

.buttons:active {
  transform: scale(0.95);
}
// .buttons:hover {
//   height: 15%;
//   width: 20%;
//   border-radius: 50%;
//   background-color: #c1c2c1;
//   border-bottom: 4px solid #707070;
//   // border-bottom: 0px;
//   display: flex;
//   flex-flow: column;
//   justify-content: center;
//   align-items: center;
//   span {
//     color: #fff;
//     font-size: 25px;
//   }
//   span:nth-child(2) {
//     color: #fff;
//     font-size: 30px;
//   }
// }
.images {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  border-radius: 10%;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
}

.stroke1 {
  display: block;
  position: absolute;
  // background-color: black;
  width: 34%;
  height: 34%;
  top: 0%;
  left: 0;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke2 {
  display: block;
  position: absolute;
  //  background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  top: 0%;
  left: 34%;
  &:hover {
    cursor: pointer;
  }
}

.stroke3 {
  display: block;
  position: absolute;
  //   background-color: black;
  z-index: 99;
  width: 34%;
  height: 33%;
  top: 34%;
  left: 33%;
  &:hover {
    cursor: pointer;
  }
}
.stroke4 {
  display: block;
  position: absolute;
  //  background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  top: 33%;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}
.stroke5 {
  display: block;
  position: absolute;
  //       background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  bottom: 0;
  left: 0;
  &:hover {
    cursor: pointer;
  }
}
.stroke6 {
  display: block;
  position: absolute;
  //       background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  bottom: 0;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}

.confettiCanvas {
  z-index: 900;
  position: absolute;
}
</style>
